import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ServicePage from "../components/service-page"

export const query = graphql`
  query ServiceTemplateQuery($id: String!) {
    servicePage: sanityService(id: { eq: $id }) {
      id
      image {
        asset {
          _id
        }
      }
      name
      price
      slug {
        current
      }
      _rawDescription
    }
  }
`

const ServiceTemplate = ({ data }) => {
  const service = data && data.servicePage
  return <Layout>{service && <ServicePage {...service} />}</Layout>
}

export default ServiceTemplate
