import React from "react"
import BlockContent from "./block-content"

import { buildImageObj } from "../utils/helpers"
import { imageUrlFor } from "../utils/image-url"

import servicePageStyles from "../styles/service-page.module.scss"

const ServicePage = props => {
  return (
    <article className={servicePageStyles.container}>
      <h2>{props.name}</h2>
      {/* <h3>{props.price}</h3> */}
      {props.image && props.image.asset && (
        <img
          src={imageUrlFor(buildImageObj(props.image))
            .width(500)
            .maxWidth(500)
            .maxHeight(300)
            .url()}
          //Add proper alt tags to sanity studio
          alt={props.name}
        />
      )}

      {//Figure out how to add images to blockcontent
      props._rawDescription && <BlockContent blocks={props._rawDescription} />}
    </article>
  )
}

export default ServicePage
